import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Card,
  Rate,
  Typography,
  Button,
  Input,
  Divider,
  Spin,
} from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import QuantitySelector from "../../../components/quantity-selector";
import { Post } from "../../../config/api/post";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Get } from "../../../config/api/get";
import { PRODUCT, REVIEWS, UPLOADS_URL } from "../../../config/constants/api";
import { addToCart } from "../../../redux/slice/cartSlice";
import swal from "sweetalert";
import avatar from "../../../assets/avatar.png";
import { calculateReviewsAverage } from "../../../config/constants";
const { Title } = Typography;

const ShopDetails = () => {
  // const profileDetails = useSelector((state) => state.user.profileDetails);
  const token = useSelector((state) => state.user.userToken);
  const userData = useSelector((state) => state.user.userData);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const [reviewText, setReviewText] = useState("");
  const [rate, setRate] = useState(0);
  const dispatch = useDispatch();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const [productReviews, setProductReviews] = useState(null);
  const { id } = useParams();
  const getRank = (rating) => {
    const ratingCounts = productReviews?.reduce((acc, review) => {
      acc[review.rating] = (acc[review.rating] || 0) + 1;
      return acc;
    }, {});
    const sortedRatings = Object.keys(ratingCounts).sort(
      (a, b) => ratingCounts[b] - ratingCounts[a]
    );
    let rank = 1;
    let prevCount = null;
    for (let i = 0; i < sortedRatings.length; i++) {
      const currentRating = parseInt(sortedRatings[i]);
      const currentCount = ratingCounts[currentRating];

      if (currentCount !== prevCount) {
        rank = i + 1;
        prevCount = currentCount;
      }

      if (currentRating === rating) {
        return rank;
      }
    }
    return 0;
  };
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, [slider1?.current, slider2?.current, allImages]);
  const getReviews = () => {
    Get(`${REVIEWS.getProductReviews}${id}`, token)
      .then((response) => {
        setProductReviews(response?.data?.docs);
      })
      .catch((err) => {
        console.log("Error Fetching Reviews", err);
      });
  };
  const handleSubmit = () => {
    let data = {
      personName: userData?.firstName + " " + userData?.lastName,
      description: reviewText,
      type: "PRODUCT",
      rating: rate,
      image: userData?.image ? userData?.image : "",
      product: id,
    };
    Post(REVIEWS.addReview, data, token)
      .then((response) => {
        if (response?.status) {
          swal("Success", "Review Added Sucessfully", "success");
          setReviewText("");
          setRate(0);
          getReviews();
        }
      })
      .catch((err) => {
        console.log("Error", err);
        swal("Error", err?.response?.data?.message, "error");
      });
  };
  useEffect(() => {
    getReviews();
  }, []);

  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const getProductDetails = () => {
    setLoading(true);
    Get(`${PRODUCT.getProduct}${id}`, token)
      .then((response) => {
        setProductDetails(response?.data);
        setAllImages([response?.data?.image, ...response?.data?.gallery]);
      })
      .catch((err) => {
        console.log("Error Fetching Product Details", err);
      });
    setLoading(false);
  };
  useEffect(() => {
    getProductDetails();
  }, []);
  const sliderSettings = {
    arrows: false,
  };
  const sliderSettings2 = {};
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const handleSelectedQuantityChange = (newQuantity) => {
    setSelectedQuantity(newQuantity);
  };
  const handleAddToCart = (productName, productPrice, productImage) => {
    dispatch(
      addToCart({
        name: productName,
        price: productPrice,
        quantity: selectedQuantity,
        image: productImage,
      })
    );
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Product Details</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {!loading ? (
              <Col lg={19}>
                <Card className="details-card">
                  <Row align="middle" gutter={16}>
                    <Col lg={10}>
                      <div
                        className="one-product"
                        style={{ paddingRight: "30px" }}
                      >
                        <div className="search-img">
                          <div>
                            <Slider
                              asNavFor={nav2}
                              ref={slider1}
                              {...sliderSettings}
                            >
                              {Array.isArray(allImages) &&
                                allImages.map((image, index) => {
                                  return (
                                    <div className="gray-bg" key={index}>
                                      <span>
                                        {" "}
                                        <img
                                          src={UPLOADS_URL + image}
                                          alt=""
                                          className="img-fluid"
                                          style={{ width: "100%" }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                            </Slider>
                            <Slider
                              asNavFor={nav1}
                              ref={slider2}
                              slidesToShow={productDetails?.gallery?.length}
                              swipeToSlide={true}
                              focusOnSelect={true}
                              {...sliderSettings2}
                            >
                              {Array.isArray(allImages) &&
                                allImages.map((image, index) => {
                                  return (
                                    <div key={index} className="slider-nav">
                                      <span>
                                        <img
                                          src={UPLOADS_URL + image}
                                          alt=""
                                          className="img-fluid"
                                          style={{
                                            width: "100%",
                                            height: "90px",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                            maxWidth: "150px",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={14}>
                      <div
                        className="room-details"
                        style={{ paddingBottom: "0" }}
                      >
                        <div>
                          <h4 className="text-26">{productDetails?.title}</h4>
                        </div>
                        <div className="search-result-detail-btnbox">
                          <h6 className="per-day-price">
                            ${productDetails?.price + ".00"}
                          </h6>
                        </div>
                      </div>
                      <Divider dashed />
                      <div className="search-img-box-right additional-details">
                        <h5 className="heading-18">Additional Details</h5>
                        <p className="web-p">{productDetails?.description}</p>
                      </div>
                      <div className="quantity">
                        <span className="heading-18">Quantity</span>

                        <QuantitySelector
                          value={selectedQuantity}
                          onChange={handleSelectedQuantityChange}
                        />
                      </div>
                      <div>
                        <Button
                          type=""
                          className="web-btn"
                          style={{ margin: "5px" }}
                          onClick={() => {
                            handleAddToCart(
                              productDetails.title,
                              productDetails.price,
                              productDetails.image
                            );
                          }}
                        >
                          Add To Cart
                        </Button>
                        <Button
                          type=""
                          className="web-btn"
                          style={{ margin: "5px" }}
                          onClick={() => navigate("/checkOut")}
                        >
                          Buy Now
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ) : (
              <Col
                lg={19}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "150px 0px",
                }}
              >
                <Spin />
              </Col>
            )}
          </Row>

          <Row justify="center">
            <Col lg={19}>
              <div className="details-card">
                <Title level={3} className="text-24-bold pb-5">
                  Rating & Reviews
                </Title>

                <Row justify={"space-between"}>
                  <Col lg={12} xs={24}>
                    <Title level={2} className="rating-5">
                      {calculateReviewsAverage(productReviews)}
                      <span>/{productReviews?.length}</span>
                    </Title>
                    <div className="pb-3">
                      <Rate
                        allowHalf
                        defaultValue={calculateReviewsAverage(productReviews)}
                        disabled
                      />
                    </div>
                    <a href="#_" className="blue-link">
                      {productReviews?.length + " Reviews"}
                    </a>
                  </Col>
                  {Array.isArray(productReviews) && (
                    <Col lg={12} xs={24}>
                      {Array.from({ length: 5 }, (_, i) => (
                        <div
                          key={i}
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="side">
                            <div className="star-18">
                              <Rate allowHalf defaultValue={i + 1} disabled />
                            </div>
                          </div>
                          <div className="middle">
                            <div
                              className={`bar-container bar-${
                                6 - getRank(i + 1)
                              }`}
                            ></div>
                          </div>
                          <div className="side right">
                            <div>
                              {
                                productReviews?.filter(
                                  (review) => review.rating === i + 1
                                ).length
                              }
                            </div>
                          </div>
                        </div>
                      ))}
                    </Col>
                  )}
                </Row>

                {Array.isArray(productReviews) && productReviews.length > 0 ? (
                  productReviews.map((review, index) => {
                    return (
                      <div key={index} className="review-box">
                        <div className="for-media-object">
                          <div className="flex-shrink-0">
                            <img
                              src={
                                review?.image === ""
                                  ? avatar
                                  : UPLOADS_URL + review?.image
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <div className="star-18">
                              <Rate
                                allowHalf
                                defaultValue={review?.rating}
                                disabled
                              />
                            </div>
                            <h6>{review?.personName}</h6>
                            <p>{review?.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      margin: "30px 0px",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    No Reviews to Show
                  </p>
                )}

                {/* Write a Review */}
                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="form-label">Write a Review</h6>
                    <div className="rate">
                      <Rate
                        onChange={(rate) => {
                          setRate(rate);
                        }}
                        value={rate}
                      />
                    </div>
                  </div>
                  <Input.TextArea
                    className="form-control web-textarea"
                    rows={5}
                    onChange={(e) => {
                      setReviewText(e.target.value);
                    }}
                    value={reviewText}
                  />
                </div>

                <div className="text-center">
                  <Button
                    type="primary"
                    className="btn web-btn"
                    onClick={handleSubmit}
                    disabled={reviewText === "" || rate === 0}
                  >
                    Post Review
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ShopDetails;
