import testiProfilePic from "../../assets/testi-profile.png";
import clientlogo1 from "../../assets/ver.png";
import clientlogo2 from "../../assets/pd.png";
import clientlogo3 from "../../assets/sq.png";
import clientlogo4 from "../../assets/client-logo4.png";
import clientlogo5 from "../../assets/client-logo5.png";
import clientlogo6 from "../../assets/client-logo6.png";

import stayBookingimg from "../../assets/stay-bookingimg.png";
import dropcardimg from "../../assets/skydrive-img.png";
import reviewspro from "../../assets/reviews-pro.png";
import instructorspro from "../../assets/instructors-pro.png";
import myprofilepic from "../../assets/profileimg.png";
import flagimg from "../../assets/flag.png";
import glasses from "../../assets/glasses.png";
import helmet from "../../assets/helmet.png";
import skycamera from "../../assets/skycamera.png";
import gloves from "../../assets/gloves.png";
import sellerimg from "../../assets/seller-img.png";
import giftcardimg from "../../assets/giftcardimg.png";
import { Link } from "react-router-dom";


export const testimonials = [
  {
    id: 1,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    id: 2,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    id: 3,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    id: 4,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    id: 5,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    id: 6,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    id: 7,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
  {
    id: 8,
    testiTitle: "Amazing & Adventures",
    profileName: "Walter Petrik",
    date: "Oct 27 - 08 min ago",
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
  },
];

export const clientTestimonials = [
  {
    id: 1,
    pic: <img src={clientlogo1} alt="" className="img-fluid" />,
  },
  {
    id: 2,
    pic: <img src={clientlogo2} alt="" className="img-fluid" />,
  },
  {
    id: 3,
    pic: <img src={clientlogo3} alt="" className="img-fluid" />,
  },
  {
    id: 4,
    pic: <img src={clientlogo1} alt="" className="img-fluid" />,
  },
  {
    id: 5,
    pic: <img src={clientlogo2} alt="" className="img-fluid" />,
  },
  {
    id: 6,
    pic: <img src={clientlogo3} alt="" className="img-fluid" />,
  },
  {
    id: 7,
    pic: <img src={clientlogo1} alt="" className="img-fluid" />,
  },
  {
    id: 8,
    pic: <img src={clientlogo2} alt="" className="img-fluid" />,
  },
  {
    id: 9,
    pic: <img src={clientlogo3} alt="" className="img-fluid" />,
  },
];

// export const clientTestimonials = [
//   {
//     id: 1,
//     pic: <img src={clientlogo1} alt="" className="img-fluid" />,
//   },
//   {
//     id: 2,
//     pic: <img src={clientlogo2} alt="" className="img-fluid" />,
//   },
//   {
//     id: 3,
//     pic: <img src={clientlogo3} alt="" className="img-fluid" />,
//   },
//   {
//     id: 4,
//     pic: <img src={clientlogo4} alt="" className="img-fluid" />,
//   },
//   {
//     id: 5,
//     pic: <img src={clientlogo5} alt="" className="img-fluid" />,
//   },
//   {
//     id: 6,
//     pic: <img src={clientlogo6} alt="" className="img-fluid" />,
//   },
// ];

export const bookingDate = [
  {
    id: 1,
    pic: (
      <img
        src={stayBookingimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    statusText: "Completed",
    time: "12:00 PM",
    date: "29 Sep 2023",
    day: "02 Days",
    paidAmmont: "$80.00/Paid",
    title: "Elegantly Sobha Hartlands",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    lengthStay: "02 Days Stay",
    roomType: "Studio with Balcony",
    bathroom: "Private studio • 1 bathroom • 30m²",
    beds: "2 beds (1 king, 1 sofa bed",
    included: "Breakfast included",
    price: "80.00",
    duration: "2 Night, 2 Adults",
    rating: 4,
    loctaion: "ABC road, 123 street New York",
  },
  {
    id: 2,
    pic: (
      <img
        src={stayBookingimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    statusText: "Ongoing",
    time: "12:00 PM",
    date: "29 Sep 2023",
    day: "02 Days",
    paidAmmont: "$80.00/Paid",
    title: "Elegantly Sobha Hartlands",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    lengthStay: "02 Days Stay",
    roomType: "Studio with Balcony",
    bathroom: "Private studio • 1 bathroom • 30m²",
    beds: "2 beds (1 king, 1 sofa bed",
    included: "Breakfast included",
    price: "80.00",
    duration: "2 Night, 2 Adults",
    rating: 5,
    loctaion: "ABC road, 123 street New York",
  },
  {
    id: 3,
    pic: (
      <img
        src={stayBookingimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    statusText: "Upcoming",
    time: "12:00 PM",
    date: "29 Sep 2023",
    day: "02 Days",
    paidAmmont: "$80.00/Paid",
    title: "Elegantly Sobha Hartlands",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    lengthStay: "02 Days Stay",
    roomType: "Studio with Balcony",
    bathroom: "Private studio • 1 bathroom • 30m²",
    beds: "2 beds (1 king, 1 sofa bed",
    included: "Breakfast included",
    price: "80.00",
    duration: "2 Night, 2 Adults",
    rating: 5,
    loctaion: "ABC road, 123 street New York",
  },
  {
    id: 4,
    pic: (
      <img
        src={stayBookingimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    statusText: "Completed",
    time: "12:00 PM",
    date: "29 Sep 2023",
    day: "02 Days",
    paidAmmont: "$80.00/Paid",
    title: "Elegantly Sobha Hartlands",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    lengthStay: "02 Days Stay",
    roomType: "Studio with Balcony",
    bathroom: "Private studio • 1 bathroom • 30m²",
    beds: "2 beds (1 king, 1 sofa bed",
    included: "Breakfast included",
    price: "80.00",
    duration: "2 Night, 2 Adults",
    rating: 4,
    loctaion: "ABC road, 123 street New York",
  },
  {
    id: 5,
    pic: (
      <img
        src={stayBookingimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    statusText: "Ongoing",
    time: "12:00 PM",
    date: "29 Sep 2023",
    day: "02 Days",
    paidAmmont: "$80.00/Paid",
    title: "Elegantly Sobha Hartlands",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    lengthStay: "02 Days Stay",
    roomType: "Studio with Balcony",
    bathroom: "Private studio • 1 bathroom • 30m²",
    beds: "2 beds (1 king, 1 sofa bed",
    included: "Breakfast included",
    price: "80.00",
    duration: "2 Night, 2 Adults",
    rating: 5,
    loctaion: "ABC road, 123 street New York",
  },
  {
    id: 6,
    pic: (
      <img
        src={stayBookingimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    statusText: "Completed",
    time: "12:00 PM",
    date: "29 Sep 2023",
    day: "02 Days",
    paidAmmont: "$80.00/Paid",
    title: "Elegantly Sobha Hartlands",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    lengthStay: "02 Days Stay",
    roomType: "Studio with Balcony",
    bathroom: "Private studio • 1 bathroom • 30m²",
    beds: "2 beds (1 king, 1 sofa bed",
    included: "Breakfast included",
    price: "80.00",
    duration: "2 Night, 2 Adults",
    rating: 5,
    loctaion: "ABC road, 123 street New York",
  },
];

export const priceSummary = [
  {
    id: 1,
    totalNights: "02",
    stayCharges: "$100.00",
    cleaningCharges: "$40.00",
    vat: "$5.00",
    totalPaid: "$5.00",
  },
];

export const reviews = [
  {
    id: 1,
    reviewsProfile: (
      <img
        src={reviewspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    reviewName: "James Anderson",
    rating: 5,
    reviewDiscretion:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 2,
    reviewsProfile: (
      <img
        src={reviewspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    rating: 4,
    reviewName: "James Anderson",
    reviewDiscretion:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];

export const dropzoneData = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Jump Switzerland Tickets",
    companylink: "ABC DROPZONE",
    subtitle: "Tandem Jump from 13,000 Ft",
    price: "425.00",
    rating: 4,
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    time: "12:00 PM",
    date: "29 Sep 2023",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
  },
  {
    id: 2,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Jump Switzerland Tickets",
    companylink: "ABC DROPZONE",
    subtitle: "Tandem Jump from 13,000 Ft",
    price: "425.00",
    rating: 4,
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    time: "12:00 PM",
    date: "29 Sep 2023",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
  },
];

export const dropzonecompanysData = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Jump Switzerland Tickets",
    subtitle: "Tandem Jump from 13,000 Ft",
    price: "425.00",
    rating: 4,
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    time: "12:00 PM",
    date: "29 Sep 2023",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    loctaion: "ABC road, 123 street New York",
  },
  {
    id: 2,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Jump Switzerland Tickets",
    subtitle: "Tandem Jump from 13,000 Ft",
    price: "425.00",
    rating: 4,
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    time: "12:00 PM",
    date: "29 Sep 2023",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    loctaion: "ABC road, 123 street New York",
  },
];

export const dzbriefingData = [
  {
    id: 1,
    pic: (
      <iframe
        width="451"
        height="320"
        src="https://www.youtube.com/embed/xVuGAyC5pv0"
        title="parachute final_2.mp4"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
    title: "Jump Switzerland Tickets",
    Discretion:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
];

export const companyJumpprice = [
  {
    id: 1,
    tandemJump: "02",
    soloJump: "$100.00",
    affJump: "$40.00",
    totalPaid: "$5.00",
  },
];

export const jobsData = [
  {
    id: 1,
    jobTitle: "ABC Job Title",
    loctaion: "ABC road, 123 street New York",
    experience: "3-5 years",
    posted: "May 20, 2023",
    loctaion2: "California",
  },
  {
    id: 2,
    jobTitle: "ABC Job Title",
    loctaion: "ABC road, 123 street New York",
    experience: "3-5 years",
    posted: "May 20, 2023",
    loctaion2: "California",
  },
  {
    id: 3,
    jobTitle: "ABC Job Title",
    loctaion: "ABC road, 123 street New York",
    experience: "3-5 years",
    posted: "May 20, 2023",
    loctaion2: "California",
  },
];

export const responsibilities = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Curabitur non lectus a lorem bibendum ullamcorper ut in ex",
  "Fusce vel diam eu orci varius lacinia.",
  "Morbi pharetra ante sit amet elit ullamcorper dapibus.",
];
export const experience = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Curabitur non lectus a lorem bibendum ullamcorper ut in ex",
  "Fusce vel diam eu orci varius lacinia.",
  "Morbi pharetra ante sit amet elit ullamcorper dapibus.",
];
export const certification = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
];

export const personalAttributes = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Curabitur non lectus a lorem bibendum ullamcorper ut in ex",
  "Fusce vel diam eu orci varius lacinia.",
  "Morbi pharetra ante sit amet elit ullamcorper dapibus.",
];
export const requiredSkills = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Curabitur non lectus a lorem bibendum ullamcorper ut in ex",
  "Morbi pharetra ante sit amet elit ullamcorper dapibus.",
];

export const instructorsData = [
  {
    id: 1,
    pic: (
      <img
        src={instructorspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    instructorName: "John Doe1",
    instructortitle: "(Instructor)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    cashapp: "123456789",
    venmo: "123456789",
  },
  {
    id: 2,
    pic: (
      <img
        src={instructorspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    instructorName: "John Doe2",
    instructortitle: "(Instructor)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    cashapp: "123456789",
    venmo: "123456789",
  },
  {
    id: 3,
    pic: (
      <img
        src={instructorspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    instructorName: "John Doe3",
    instructortitle: "(Instructor)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    cashapp: "123456789",
    venmo: "123456789",
  },
];

export const upcomingEventsData = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    date: "2024-02-05T00:00:00.000+00:00",
    title:
      "Kirk Verner Jeana Billings – 20-ways at Skydive City! USPA or National Aero Club Membership required. Not Open to the Public. .",
    description: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the when an unknown printer took a galley of type and scrambled it
        to make a type specimen book.It has survived not only five centuries,
        but also the leap into electronic typesetting, remaining essentially
        unchanged. <br></br>
        <br></br>
        It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. It was
        popularised in the 1960s with the release of Letraset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software
        like Aldus PageMaker including versions of Lorem Ipsum.
      </>
    ),
  },
  {
    id: 2,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    date: "2024-04-09T00:00:00.000+00:00",
    title:
      "Kirk Verner Jeana Billings – 20-ways at Skydive City! USPA or National Aero Club Membership required. Not Open to the Public. .",
    description: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the when an unknown printer took a galley of type and scrambled it
        to make a type specimen book.It has survived not only five centuries,
        but also the leap into electronic typesetting, remaining essentially
        unchanged. <br></br>
        <br></br>
        It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. It was
        popularised in the 1960s with the release of Letraset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software
        like Aldus PageMaker including versions of Lorem Ipsum.
      </>
    ),
  },
  {
    id: 3,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    date: "2024-02-05T00:00:00.000+00:00",
    title:
      "Kirk Verner Jeana Billings – 20-ways at Skydive City! USPA or National Aero Club Membership required. Not Open to the Public. .",
    description: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the when an unknown printer took a galley of type and scrambled it
        to make a type specimen book.It has survived not only five centuries,
        but also the leap into electronic typesetting, remaining essentially
        unchanged. <br></br>
        <br></br>
        It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. It was
        popularised in the 1960s with the release of Letraset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software
        like Aldus PageMaker including versions of Lorem Ipsum.
      </>
    ),
  },
];

export const cardriverData = [
  {
    id: 1,
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    driverName: "CARTER JAMES",
    carName: "TOYOTA PRIUS",
    carNumber: "07DL3707",
    arrivingStatus: "Arriving",
    arrivingStatusText: "Arriving in 07 Minutes",
  },
  {
    id: 2,
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    driverName: "CARTER JAMES",
    carName: "TOYOTA PRIUS",
    carNumber: "07DL3707",
    arrivingStatus: "Arrived",
    arrivingStatusText: "Your driver has arrived !",
  },
  {
    id: 3,
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    driverName: "CARTER JAMES",
    carName: "TOYOTA PRIUS",
    carNumber: "07DL3707",
    arrivingStatus: "Started",
    arrivingStatusText: "Your ride has started!",
  },
  {
    id: 4,
    pic: <img src={testiProfilePic} alt="" className="img-fluid" />,
    driverName: "CARTER JAMES",
    carName: "TOYOTA PRIUS",
    carNumber: "07DL3707",
    arrivingStatus: "Ended",
    arrivingStatusText: "Your ride has ended!",
  },
];

export const myprofiledata = {
  fullname: "Jake Dawson",
  profilePicUser: <img src={myprofilepic} alt="" className="img-fluid" />,
  email: "jake@sample.com",
  phoneNumber: "+1 844-219-0009",
  location: "ABC Location, Town, City, Country",
  gender: "Male",
  dob: "Jan 28, 1998",
  signWaiver: "ABC Waiver.pdf",
  flag: <img src={flagimg} alt="" className="img-fluid" />,
};

export const marketplaceData = [
  {
    id: 1,
    pic: (
      <img
        src={glasses}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Dark Skydiving Goggles",
    price: "380.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 2,
    pic: (
      <img
        src={helmet}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Aero Full Face",
    price: "450.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 3,
    pic: (
      <img
        src={skycamera}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "AS-15 Angle Camera",
    price: "880.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 4,
    pic: (
      <img
        src={gloves}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Akando Windstopper Gloves",
    price: "220.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 5,
    pic: (
      <img
        src={glasses}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Dark Skydiving Goggles",
    price: "380.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 6,
    pic: (
      <img
        src={helmet}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Aero Full Face",
    price: "450.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 7,
    pic: (
      <img
        src={skycamera}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "AS-15 Angle Camera",
    price: "880.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 8,
    pic: (
      <img
        src={gloves}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Akando Windstopper Gloves",
    price: "220.00",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
];

export const shopData = [
  {
    id: 1,
    pic: (
      <img
        src={glasses}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Dark Skydiving Goggles",
    price: "380.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 2,
    pic: (
      <img
        src={helmet}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Aero Full Face",
    price: "450.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 3,
    pic: (
      <img
        src={skycamera}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "AS-15 Angle Camera",
    price: "880.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 4,
    pic: (
      <img
        src={gloves}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Akando Windstopper Gloves",
    price: "220.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 5,
    pic: (
      <img
        src={glasses}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Dark Skydiving Goggles",
    price: "380.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 6,
    pic: (
      <img
        src={helmet}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Aero Full Face",
    price: "450.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 7,
    pic: (
      <img
        src={skycamera}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "AS-15 Angle Camera",
    price: "880.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 8,
    pic: (
      <img
        src={gloves}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Akando Windstopper Gloves",
    price: "220.00",
    onlyLeft: "Only 07 left",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    sellerName: "Alan Anderson",
    sellerNumber: "+353 01 311 00",
    sellerEmail: "Testing@Example.com",
    sellerimg: (
      <img
        src={sellerimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
  },
];

export const checkoutSummary = [
  {
    id: 1,
    subtotal: "450.00",
    vat: "10.00",
    deliveryCharges: "10.00",
    totalPaid: "5.00",
  },
];

export const myordersData = [
  {
    id: 1,
    orderId: "001_4567",
    createdAt: new Date(),
    amount: 1000,
    status: "PENDING",
    customerName: "Tom Albert",
    email: "Testing@Example.com",
    paymentMethod: "Card",
    address: "3718 Davis Avenue, 94785 San Francisco ",
    statusText: "Pending",
    username: "Tom Albert",
    phoneNumber: "+1 707-787-0345",
    country: "United States",
    city: "San Francisco",
    state: "California",
    zipcode: "94107",
  },
  {
    id: 2,
    orderId: "001_4568",
    createdAt: new Date(),
    amount: 500,
    status: "ONGOING",
    customerName: "Tom Albert",
    email: "Testing@Example.com",
    paymentMethod: "Card",
    address: "3718 Davis Avenue, 94785 San Francisco ",
    statusText: "Ongoing",
    username: "Tom Albert",
    phoneNumber: "+1 707-787-0345",
    country: "United States",
    city: "San Francisco",
    state: "California",
    zipcode: "94107",
  },
  {
    id: 3,
    orderId: "001_4578",
    createdAt: new Date(),
    amount: 587,
    status: "PENDING",
    customerName: "Tom Albert",
    email: "Testing@Example.com",
    paymentMethod: "Card",
    address: "3718 Davis Avenue, 94785 San Francisco ",
    statusText: "Pending",
    username: "Tom Albert",
    phoneNumber: "+1 707-787-0345",
    country: "United States",
    city: "San Francisco",
    state: "California",
    zipcode: "94107",
  },
  {
    id: 4,
    orderId: "001_4567",
    createdAt: new Date(),
    amount: 660,
    status: "COMPLETED",
    customerName: "Tom Albert",
    email: "Testing@Example.com",
    paymentMethod: "Card",
    address: "3718 Davis Avenue, 94785 San Francisco ",
    statusText: "Completed",
    username: "Tom Albert",
    phoneNumber: "+1 707-787-0345",
    country: "United States",
    city: "San Francisco",
    state: "California",
    zipcode: "94107",
  },
  {
    id: 5,
    orderId: "001_4567",
    createdAt: new Date(),
    amount: 750,
    status: "COMPLETED",
    customerName: "Tom Albert",
    email: "Testing@Example.com",
    paymentMethod: "Card",
    address: "3718 Davis Avenue, 94785 San Francisco ",
    statusText: "Completed",
    username: "Tom Albert",
    phoneNumber: "+1 707-787-0345",
    country: "United States",
    city: "San Francisco",
    state: "California",
    zipcode: "94107",
  },
];

export const jumpReservationLogosData = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "UPCOMING",
    statusText: "Upcoming",
    bookingId: "#25874",
    customername: "Jeremy",
    company: "Ecstatic Dives",
    jumpname: "Tandem Jump",
    cost: "$450.00",
    title: "Elegantly Sobha Hartlands",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    price: "425.00",
  },
  {
    id: 2,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "ONGOING",
    statusText: "Ongoing",
    bookingId: "#25874",
    customername: "Jeremy",
    company: "Ecstatic Dives",
    jumpname: "Tandem Jump",
    cost: "$450.00",
    title: "Elegantly Sobha Hartlands",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    price: "425.00",
  },
  {
    id: 3,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "UPCOMING",
    statusText: "Upcoming",
    bookingId: "#25874",
    customername: "Jeremy",
    company: "Ecstatic Dives",
    jumpname: "Tandem Jump",
    cost: "$450.00",
    title: "Elegantly Sobha Hartlands",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    price: "425.00",
  },
  {
    id: 4,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "COMPLETED",
    statusText: "Completed",
    bookingId: "#25874",
    customername: "Jeremy",
    company: "Ecstatic Dives",
    jumpname: "Tandem Jump",
    cost: "$450.00",
    title: "Elegantly Sobha Hartlands",
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since....",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    price: "425.00",
  },
];

export const giftCardsdata = [
  {
    id: 1,
    cardPic: <img src={giftcardimg} alt="" className="img-fluid" style={{maxWidth:"100%"}} />,
    cardDate: "Date: Feb 24, 2023",
    cardAmount: "Gift Card of $100",
    couponNumber: "ACD100EDald",
    sentBy: "John Doe",
    giftCardBalance: "100",
  },
  {
    id: 2,
    cardPic: <img src={giftcardimg} alt="" className="img-fluid" />,
    cardDate: "Date: Feb 24, 2023",
    cardAmount: "Gift Card of $100",
    couponNumber: "ACD100EDald",
    sentBy: "John Doeaa",
    giftCardBalance: "100",
  },
];

export const notifications = [
  {
    id: 1,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "Dec 19, 2022",
    time: "02:00 PM",
  },
  {
    id: 2,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 3,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "Dec 19, 2022",
    time: "02:00 PM",
  },
  {
    id: 4,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 5,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 6,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 7,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
];
