import { Col, Row, Image, Space, Select, Input, Button } from "antd";
import fb from "../../assets/fb.png";
import inst from "../../assets/inst.png";
import snap from "../../assets/snap.png";
import twitter from "../../assets/twitter1.png";
import youtube from "../../assets/youtube.png";
import linkedin from "../../assets/linkedin.png";
import twitterx from "../../assets/twitterx.png";
import tiktok from "../../assets/tiktok.png";
import footerlogo from "../../assets/footer-logo.png";
import { useNavigate } from "react-router";
import {
  FaApple,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { AiFillAndroid } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const ClientFooter = () => {
  const navigate = useNavigate();
  const { Search } = Input;
  const options = [
    { value: "USD 01", label: "USD 01" },
    { value: "USD 02", label: "USD 02" },
  ];

  return (
    <>
      <footer className="ant-footer">
        <div className="ant-container-fluid">
          <Row justify="center">
            <Col lg={21}>
              <div className="footer-box ant-footer-box">
                <Row>
                  <Col lg={10} md={24}>
                    <Row gutter={[16, 16]}>
                      <Col lg={12} md={12} xs={24}>
                        <div className="for-footer-nav ant-for-footer-nav">
                          <h3>About us & More</h3>
                          <ul className="">
                            <li>
                              <a onClick={() => navigate("/aboutUs")}>
                                About Us
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/news")}>Blog</a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/news")}>News</a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/news")}>Career</a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/news")}>Logbook</a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/news")}>
                                Gift Cards
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/faqs")}>
                                Military Discount
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/faqs")}>
                                Upcoming Event
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/faqs")}>
                                Wingload Calculator
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col lg={12} md={12} xs={24}>
                        <div className="for-footer-nav ant-for-footer-nav">
                          <h3>Customer Care</h3>
                          <ul className="">
                            <li>
                              <a onClick={() => navigate("/career")}>Help</a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/help")}>FAQs</a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/termsConditions")}>
                                Sitemap
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/termsConditions")}>
                                Contact Us
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/termsConditions")}>
                                Partnership
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/termsConditions")}>
                                Privacy Policy
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/termsConditions")}>
                                Investor Retations
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/termsConditions")}>
                                Salses And Refund Policy
                              </a>
                            </li>
                            <li>
                              <a onClick={() => navigate("/termsConditions")}>
                                Terms And Conditions
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={14} md={24}>
                    <Row gutter={[16, 16]}>
                      <Col lg={18} xs={24}>
                        <div className="for-footer-nav ant-for-footer-nav">
                          <h3>Follow Us</h3>
                          <div className="social-links-new">
                            <Link>
                              <FaFacebookF />
                            </Link>
                            <Link>
                              <FaInstagram />
                            </Link>
                            <Link>
                              <FaYoutube />
                            </Link>
                            <Link>
                              <Image
                                preview={false}
                                alt={"Failed to load image"}
                                src={twitterx}
                                style={{ maxWidth: "80%" }}
                              />
                            </Link>
                            <Link>
                              <FaTiktok />
                            </Link>
                            <Link>
                              <FaLinkedinIn />
                            </Link>
                            <Link>
                              <FaWhatsapp />
                            </Link>
                          </div>
                          <h3 style={{ paddingBottom: "20px" }}>
                            Sign Up for Skydivebnb news
                          </h3>
                          <Space
                            direction="vertical"
                            size="middle"
                            style={{
                              width: "100%",
                            }}
                          >
                            <Space.Compact
                              style={{
                                width: "100%",
                              }}
                            >
                              <Input placeholder="info@SkydiveBNB.com" />
                              <Button type="primary" className="footer-submit-btn">Subscribe</Button>
                            </Space.Compact>

                            <Space.Compact
                              style={{
                                width: "100%",
                              }}
                            >
                              <Space.Compact style={{
                                width: "100%",
                              }}>
                                <Input
                                  addonBefore={
                                    <Select
                                      defaultValue="USD 01"
                                      style={{ width: 90 }}
                                      options={options}
                                    />
                                  }
                                  placeholder="Your WhatsApp Number"
                                />
                                <Button type="primary" className="footer-submit-btn">Subscribe</Button>
                              </Space.Compact>
                            </Space.Compact>
                          </Space>
                          <p className="web-p">
                            By clicking Subscribe button, you are agreeing to
                            our Privacy Policy. If you want to Unsubscribe the
                            marketing email, please proceed to our privacy
                            center
                          </p>
                        </div>
                      </Col>
                      <Col lg={6} xs={24}>
                        <div className="for-footer-nav ant-for-footer-nav">
                          <h3>Download App</h3>
                          <div className="social-links-new">
                            <Link>
                              <FaApple />
                            </Link>
                            <Link>
                              <AiFillAndroid />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={24} xs={24}>
                    <p className="copyright-p ant-copyright-p">
                    Copyrights 2024 SkydiveBNB LLC - All Rights Reserved - By skydivers for skydivers
                      <div style={{ textAlign: "center" }}>
                        <Image
                          style={{ width: "50px" }}
                          preview={false}
                          alt={"Failed to load image"}
                          src={footerlogo}
                          className=""
                        />
                      </div>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </footer>

      {/* <footer className="ant-footer">
<div className="ant-container-fluid">
  <Row justify="center">
    <Col lg={21}>
      <div className="footer-box ant-footer-box">
        <Row>
          <Col lg={24} xs={24}>
            <div style={{ textAlign: "center" }}>
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={footerlogo}
                className=""
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={6} xs={24}>
            <div className="for-footer-nav ant-for-footer-nav">
              <ul className="brd-right">
                <li>
                  <a onClick={() => navigate("/aboutUs")}>
                    About us / Our VISIONARIES
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate("/news")}>News</a>
                </li>
                <li>
                  <a onClick={() => navigate("/faqs")}>FAQs</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={6} xs={24}>
            <div className="for-footer-nav ant-for-footer-nav">
              <ul className="brd-right">
                <li>
                  <a onClick={() => navigate("/career")}>Career</a>
                </li>
                <li>
                  <a onClick={() => navigate("/help")}>Help/Contact</a>
                </li>
                <li>
                  <a onClick={() => navigate("/termsConditions")}>
                    Terms & conditions
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={6} xs={24}>
            <div className="for-footer-nav ant-for-footer-nav">
              <ul className="brd-right">
                <li>
                  <a href="index.php">Investor Relations</a>
                </li>
                <li>
                  <a href="about.php">Partnership</a>
                </li>
                <li>
                  <a href="news.php">Sitemap</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={6} xs={24}>
            <div className="for-footer-nav ant-for-footer-nav">
              <ul>
                <li>
                  <a onClick={() => navigate("/privacyPolicy")}>
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate("/privacyPolicy")}>
                    Sales & refund policy
                  </a>
                </li>
                <li>
                  <a href="about.php">Wind load Calculator</a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={24} xs={24}>
            <div className="social-links ant-social-links">
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={fb}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={twitter}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={inst}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={snap}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={youtube}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={linkedin}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={tiktok}
                  className=""
                />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={24} xs={24}>
            <p className="copyright-p ant-copyright-p">
              —© 2023-2024 Skydivebnb.com LLC, all rights
              reserved.priceline.com LLC is located at 800 Connecticut
              Ave. Norwalk, CT 06854.
              <br />
              By skydivers for Skydivers
              <div style={{ textAlign: "center" }}>
                <Image
                style={{width:"50px"}}
                  preview={false}
                  alt={"Failed to load image"}
                  src={footerlogo}
                  className=""
                />
              </div>
            </p>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
</div>
</footer> */}
    </>
  );
};

export default ClientFooter;
