import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import Homepage from "../../views/homepage";
import CreateAccount from "../../views/createAccount";
import LoginAs from "../../views/loginAs";
import Login from "../../views/login";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import MystayBookings from "../../views/mystayBookings";
import MystaysbookingsDetails from "../../views/mystayBookings/mystaysbookingsDetails";
import FindNextStay from "../../views/findNextStay";
import StaySearchResult from "../../views/findNextStay/staySearchResult";
import StaySearchResultDetails from "../../views/findNextStay/staySearchResultDetails";
import StayCheckout from "../../views/findNextStay/stayCheckout";
import DropZone from "../../views/dropZone";
import JumpDetails from "../../views/dropZone/jumpDetails";
import JumpCheckout from "../../views/dropZone/jumpCheckout";
import DropzoneCompany from "../../views/dropzoneCompany";
import SignWaivers from "../../views/signWaivers";
import MyLoads from "../../views/myLoads";
import DzBriefing from "../../views/dzBriefing";
import Calls from "../../views/calls";
import Pricing from "../../views/pricing";
import Careers from "../../views/careers";
import JobDetails from "../../views/careers/jobDetails";
import InstructorsRiggers from "../../views/instructorsRiggers";
import InstructorsRiggersDetails from "../../views/instructorsRiggers/instructorsRiggersDetails";
import UpcomingEvents from "../../views/upcomingEvents";
import UpcomingEventsDetails from "../../views/upcomingEvents/upcomingEventsDetails";
import AboutUs from "../../views/aboutUs";
import PrivacyPolicy from "../../views/privacyPolicy";
import TermsConditions from "../../views/termsConditions";
import RidesBooking from "../../views/ridesBooking";
import MapLocationAdded from "../../views/ridesBooking/mapLocationAdded";
import DriverCarArrival from "../../views/ridesBooking/driverCarArrival";
import ProtectedRoute from "../../views/protectedRoute";
import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import MarketPlace from "../../views/marketPlace";
import MarketPlaceDetail from "../../views/marketPlace/marketPlaceDetail";
import Shop from "../../views/shop";
import ShopDetails from "../../views/shop/shopDetails";
import CheckOut from "../../views/checkOut";
import MyCart from "../../views/myCart";
import MyOrders from "../../views/myOrders";
import OrderDetail from "../../views/myOrders/myorderInfo/orderDetail";
import JumpReservationLogs from "../../views/jumpReservationLogs";
import StayReservationLogs from "../../views/stayReservationLogs";
import JumpReservationDetails from "../../views/jumpReservationLogs/jumpReservationInfo/jumpReservationDetails";
import StayReservationDetails from "../../views/stayReservationLogs/stayReservationInfo/stayReservationDetails";
import CreateProfile from "../../views/profile/createProfile";
import GiftCards from "../../views/giftCards";
import PurchaseGiftCards from "../../views/giftCards/purchaseGiftCards";
import Help from "../../views/help";
import Payment from "../../views/Payment";
import Completion from "../../views/Completion";
import Notifications from "../../views/notifications";
import Signs from "../../views/MySigns";
import LocalWeather from "../../views/localWeather";
import News from "../../views/News";
import Faqs from "../../views/Faqs";
import Career from "../../views/Career";
import { useSelector } from "react-redux";
import socket from '../socket/index';
import { useEffect } from "react";

const MyRouter = () => {
  const token = useSelector((state) => state.user.userToken);
  const user = useSelector((state) => state.user.userData);
  useEffect(()=>{
    if(token){
      socket.emit('setup', user);
    }
  },[])
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/"
          index
          element={
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
                bg={false}
                footer={false}
              >
                <Homepage />
              </ClientLayout>
          }
        />

        <Route
          path="/createAccount"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CreateAccount />
            </ClientLayout>
          }
        />
        <Route
          path="/loginAs"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <LoginAs />
            </ClientLayout>
          }
        />
        <Route
          path="/login"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Login />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-1"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword1 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-2"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword2 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-3"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword3 />
            </ClientLayout>
          }
        />
        <Route
          path="/mystayBookings"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <MystayBookings />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mystayBookings/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <MystaysbookingsDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/findNextStay"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <FindNextStay />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staySearchResult"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <StaySearchResult />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staySearchResult/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <StaySearchResultDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/stayCheckout"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <StayCheckout />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropZone"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <DropZone />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropZone/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <JumpDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/jumpCheckout"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <JumpCheckout />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <DropzoneCompany />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/signWaiver"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <SignWaivers />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/myLoads"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <MyLoads />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/dzBriefing"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <DzBriefing />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/calls"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Calls />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/pricing"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Pricing />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/careers"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Careers />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/careers/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <JobDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/instructorsRiggers"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <InstructorsRiggers />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/instructorsRiggers/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <InstructorsRiggersDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/upcomingEvents"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <UpcomingEvents />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/upcomingEvents"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <UpcomingEvents />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/upcomingEvents/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <UpcomingEventsDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/upcomingEvents/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <UpcomingEventsDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/aboutUs"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <AboutUs />
            </ClientLayout>
          }
        />
        <Route
          path="/privacyPolicy"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PrivacyPolicy />
            </ClientLayout>
          }
        />
        <Route
          path="/termsConditions"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <TermsConditions />
            </ClientLayout>
          }
        />
        <Route
          path="/ridesBooking"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <RidesBooking />
            </ClientLayout>
          }
        />
        <Route
          path="/mapLocationAdded"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <MapLocationAdded />
            </ClientLayout>
          }
        />
        <Route
          path="/driverCarArrival"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <DriverCarArrival />
            </ClientLayout>
          }
        />
        <Route
          path="/profile"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Profile />
            </ClientLayout>
          }
        />
        <Route
          path="/editProfile"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <EditProfile />
            </ClientLayout>
          }
        />
        <Route
          path="/changePassword"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ChangePassword />
            </ClientLayout>
          }
        />
        <Route
          path="/marketPlace"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <MarketPlace />
            </ClientLayout>
          }
        />
        <Route
          path="/marketPlace/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <MarketPlaceDetail />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Shop />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ShopDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkOut"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <CheckOut />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/myCart"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <MyCart />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/myOrders"
          index
          element={
            // <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <MyOrders />
              </ClientLayout>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/myOrders/myorderInfo/:id"
          index
          element={
            // <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <OrderDetail />
              </ClientLayout>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/jumpReservationLogs"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <JumpReservationLogs />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/jumpReservationLogs/jumpReservationInfo/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <JumpReservationDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/stayReservationLogs"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <StayReservationLogs />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/stayReservationLogs/stayReservationInfo/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <StayReservationDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/createProfile"
          index
          element={
            // <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <CreateProfile />
              </ClientLayout>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/giftCards"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <GiftCards />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/giftCards/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <PurchaseGiftCards />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/help"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Help />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Payment />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/completion"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Completion />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Notifications />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mySigns"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Signs />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzoneCompany/localWeather"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <LocalWeather />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/completion"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                < Completion/>
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/news"
          index
          element={
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <News />
              </ClientLayout>
          }
        />
        <Route
          path="/faqs"
          index
          element={
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Faqs />
              </ClientLayout>
          }
        />
        <Route
          path="/career"
          index
          element={
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Career />
              </ClientLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
