import React from "react";
import { Row, Col, Typography, Button, Image } from "antd";
import appstore from "../../../assets/app-store.png";
import googleplay from "../../../assets/google-play.png";
import divingimg from "../../../assets/diving-img.webp";
import cardsicons5 from "../../../assets/cards-icons5.png";
import cardsicons2 from "../../../assets/cards-icons2.png";
import cardsicons3 from "../../../assets/cards-icons3.png";
import cardsicons4 from "../../../assets/cards-icons4.png";
import cardsicons from "../../../assets/cards-icons.png";
import { useNavigate } from "react-router";
// import 'antd/dist/antd.css';

const { Title } = Typography;

const Topbanner = () => {
  const navigate = useNavigate();
  return (
    <div className="banner-section">
      <div className="container-fluid px-0">
      <section className="ant-section">
      <div className="ant-container-fluid">
        <Row justify="center">
          <Col lg={16} md={22}>
            <Row gutter={[16, 16]} justify="center">
              <Col lg={4} md={4}>
                <div
                  className="home-service-cards"
                  onClick={() => navigate("/ridesBooking")}
                  style={{ cursor: "pointer" }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={cardsicons5}
                    className=""
                  />
                  <h6 className="urb-18">Rides</h6>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div
                  className="home-service-cards"
                  onClick={() => navigate("/findNextStay")}
                  style={{ cursor: "pointer" }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={cardsicons2}
                    className=""
                  />
                  <h6 className="urb-18">Stays</h6>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div
                  className="home-service-cards"
                  onClick={() => navigate("/dropZone")}
                  style={{ cursor: "pointer" }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={cardsicons3}
                    className=""
                  />
                  <h6 className="urb-18">Jumps</h6>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div
                  className="home-service-cards"
                  onClick={() => navigate("/marketPlace")}
                  style={{ cursor: "pointer" }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={cardsicons4}
                    className=""
                  />
                  <h6 className="urb-18">Marketplace</h6>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div
                  className="home-service-cards"
                  onClick={() => navigate("/shop")}
                  style={{ cursor: "pointer" }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={cardsicons}
                    className=""
                  />
                  <h6 className="urb-18">Shop</h6>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>

    
        <Row gutter={0}>
          {/* <div className="banner-text-box">
            <h3 className="experience-text">Welcome to</h3>
            <h1>skyDive bnb</h1>
            <div className="banner-apps-img pt-2" style={{ textAlign: 'right', paddingTop: "10px" }}>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={appstore}
                  className=""
                />
              </a>
              <a href="#_">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={googleplay}
                  className=""
                />
              </a>
            </div>
          </div> */}
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={divingimg}
            className="diving-img"
          />
        </Row>
      </div>
    </div>
  );
};

export default Topbanner;
