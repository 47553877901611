import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Card,
  Checkbox,
  Rate,
  Typography,
  Button,
  Input,
  Spin,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaArrowLeftLong, FaLocationDot } from "react-icons/fa6";
import { MdBalcony, MdOutlineKingBed } from "react-icons/md";
import { FaShower } from "react-icons/fa";
import Slider from "react-slick";
import { Get } from "../../../config/api/get";
import { REVIEWS, STAY, UPLOADS_URL } from "../../../config/constants/api";
import {
  calculateReviewsAverage,
  extractDate,
} from "../../../config/constants/index";
import { useSelector } from "react-redux";
import avatar from "../../../assets/avatar.png";
import { Post } from "../../../config/api/post";
import swal from "sweetalert";
const { Title } = Typography;

const AsNavFor = () => {
  const location = useLocation();
  const token = useSelector((state) => state.user.userToken);
  const userData = useSelector((state) => state.user.userData);
  // const profile = useSelector((state) => state.user.profileDetails);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [reviewText, setReviewText] = useState("");
  const [rate, setRate] = useState(0);
  const [roomReviews, setRoomReviews] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [extraCharges, setExtraCharges] = useState(false);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const { to, from, availableDates } = location?.state;
  useEffect(() => {
    setNav1(slider1?.current);
    setNav2(slider2?.current);
  }, [slider1?.current, slider2?.current, allImages]);
  const [roomDetails, setRoomDetails] = useState(null);
  const [profileDetails, setProfileDetails] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const handleSubmit = () => {
    let data = {
      personName: userData?.firstName + " " + userData?.lastName,
      description: reviewText,
      type: "ROOM",
      rating: rate,
      image: userData?.image ? userData?.image : "",
      room: id,
    };
    Post(REVIEWS.addReview, data, token)
      .then((response) => {
        if (response?.status) {
          swal("Success", "Review Added Sucessfully", "success");
          setReviewText("");
          setRate(0);
          getRoomReviews();
        }
      })
      .catch((err) => {
        console.log("Error", err);
        swal("Error", err?.response?.data?.message, "error");
      });
  };
 
  const getRoomDetails = () => {
    Get(`${STAY.getRoom}${id}`, token)
      .then((response) => {
        setRoomDetails(response?.data?.room);
        setProfileDetails(response?.data?.profile);
        setAllImages([
          response?.data?.room?.image,
          ...response?.data?.room?.gallery,
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getRoomReviews = () => {
    Get(`${REVIEWS.getRoomReviews}${id}`, token)
      .then((response) => {
        setRoomReviews(response?.data?.docs);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getRoomDetails();
  }, []);
  useEffect(() => {
    getRoomReviews();
  }, []);
  const getRank = (rating) => {
    const ratingCounts = roomReviews?.reduce((acc, review) => {
      acc[review.rating] = (acc[review.rating] || 0) + 1;
      return acc;
    }, {});
    const sortedRatings = Object.keys(ratingCounts).sort(
      (a, b) => ratingCounts[b] - ratingCounts[a]
    );
    let rank = 1;
    let prevCount = null;
    for (let i = 0; i < sortedRatings?.length; i++) {
      const currentRating = parseInt(sortedRatings[i]);
      const currentCount = ratingCounts[currentRating];

      if (currentCount !== prevCount) {
        rank = i + 1;
        prevCount = currentCount;
      }
      if (currentRating === rating) {
        return rank;
      }
    }
    return 0;
  };
  const sliderSettings = {
    arrows: false,
    // other settings...
  };
  const sliderSettings2 = {
    // arrows: false,
    // style: {
    //   margin: "20px",
    // },
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Book a Stay - room detailS</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={19}>
              {roomDetails ? (
                <Card className="details-card">
                  <Row align="middle" gutter={16}>
                    <Col lg={10}>
                      <div
                        className="one-product"
                        style={{ paddingRight: "30px" }}
                      >
                        <div className="search-img">
                          <div>
                            <Slider
                              asNavFor={nav2}
                              ref={slider1}
                              {...sliderSettings}
                            >
                              {Array.isArray(allImages) &&
                                allImages.map((image, index) => {
                                  return (
                                    <div key={index}>
                                      <span>
                                        <img
                                          src={UPLOADS_URL + image}
                                          alt=""
                                          className="img-fluid"
                                          style={{
                                            width: "100%",
                                            maxHeight: "300px",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                            </Slider>
                            <Slider
                              asNavFor={nav1}
                              ref={slider2}
                              slidesToShow={roomDetails?.gallery?.length}
                              swipeToSlide={true}
                              focusOnSelect={true}
                              {...sliderSettings2}
                            >
                              {Array.isArray(allImages) &&
                                allImages.map((image, index) => {
                                  return (
                                    <div key={index} className="slider-nav">
                                      <span>
                                        <img
                                          src={UPLOADS_URL + image}
                                          alt=""
                                          className="img-fluid"
                                          style={{
                                            width: "100%",
                                            height: "90px",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                            maxWidth: "150px",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                            </Slider>
                          </div>
                          {(Array.isArray(roomReviews) && roomReviews.length>0)&&<div className="rating-box">
                            <Rate
                              allowHalf
                              defaultValue={calculateReviewsAverage(
                                roomReviews
                              )}
                              disabled
                            />
                          </div>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={14}>
                      <div className="room-details">
                        <div>
                          <h4 className="text-26">{roomDetails?.title}</h4>
                          {profileDetails && (
                            <p className="icon-span">
                              <FaLocationDot />{" "}
                              {profileDetails?.location?.address +
                                ", " +
                                profileDetails?.location?.street +
                                " " +
                                profileDetails?.location?.state}
                            </p>
                          )}
                          {availableDates && (
                            <p className="icon-span">
                              {`Available from ${extractDate(
                                availableDates[0]
                              )} to ${extractDate(
                                availableDates[availableDates.length - 1]
                              )}`}
                            </p>
                          )}
                        </div>
                        <div className="search-result-detail-btnbox">
                          <h6 className="per-day-price">
                            ${roomDetails?.pricing}
                            <span>/Per day</span>
                          </h6>
                        </div>
                      </div>
                      <div className="search-img-box-right additional-details">
                        <h5 className="heading-18">Additional Details</h5>
                        <p>
                          <MdBalcony />
                          {roomDetails?.description}
                        </p>
                        <p>
                          <FaShower />
                          {roomDetails?.bathrooms + " Bathrooms"}
                        </p>
                        <p>
                          <MdOutlineKingBed />
                          {roomDetails.beds + " Beds"}
                        </p>
                      </div>
                      <div
                        className="search-img-box-right"
                        style={{ paddingBottom: "20px" }}
                      >
                        <h5 className="heading-18 py-2">Extra Charges</h5>
                        <Checkbox
                          id="inlineCheckbox2"
                          className="form-check-input"
                          value="option2"
                          onChange={() => {
                            setExtraCharges(!extraCharges);
                          }}
                        >
                          $20 extra charges for room cleaning
                        </Checkbox>
                      </div>
                      <div>
                        <Button
                          type=""
                          className="web-btn"
                          onClick={() =>
                            navigate("/stayCheckout", {
                              state: {
                                to,
                                from,
                                roomDetails,
                                extraCharges,
                                locationDetails: profileDetails?.location,
                                availableDates,
                              },
                            })
                          }
                        >
                          Book Now
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card>
                  <Col
                    xs={23}
                    md={21}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "150px 0px",
                    }}
                  > 
                    <Spin />
                  </Col>
                </Card>
              )}
            </Col>
          </Row>

          <Row justify="center">
            <Col lg={19}>
              <div className="details-card">
                <Title level={3} className="text-24-bold pb-5">
                  Rating & Reviews
                </Title>

                <Row justify={"space-between"}>
                  <Col lg={12} xs={24}>
                    <Title level={2} className="rating-5">
                      {calculateReviewsAverage(roomReviews)}
                      <span>/{roomReviews?.length}</span>
                    </Title>
                    <div className="pb-3">
                      <Rate
                        allowHalf
                        defaultValue={calculateReviewsAverage(roomReviews)}
                        disabled
                      />
                    </div>
                    <a href="#_" className="blue-link">
                      {roomReviews?.length + " "} Reviews
                    </a>
                  </Col>

                  {Array.isArray(roomReviews) && (
                    <Col lg={12} xs={24}>
                      {Array.from({ length: 5 }, (_, i) => (
                        <div
                          key={i}
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="side">
                            <div className="star-18">
                              <Rate allowHalf defaultValue={i + 1} disabled />
                            </div>
                          </div>
                          <div className="middle">
                            <div
                              className={`bar-container bar-${
                                6 - getRank(i + 1)
                              }`}
                            ></div>
                          </div>
                          <div className="side right">
                            <div>
                              {
                                roomReviews?.filter(
                                  (review) => review.rating === i + 1
                                ).length
                              }
                            </div>
                          </div>
                        </div>
                      ))}
                    </Col>
                  )}
                </Row>

                {Array.isArray(roomReviews) && roomReviews.length > 0 ? (
                  roomReviews.map((review, index) => {
                    return (
                      <div key={index} className="review-box">
                        <div className="for-media-object">
                          <div className="flex-shrink-0">
                            <img
                              src={
                                review?.image === ""
                                  ? avatar
                                  : UPLOADS_URL + review?.image
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <div className="star-18">
                              <Rate
                                allowHalf
                                defaultValue={review?.rating}
                                disabled
                              />
                            </div>
                            <h6>{review?.personName}</h6>
                            <p>{review?.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      margin: "30px 0px",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    No Reviews to Show
                  </p>
                )}
                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="form-label">Write a Review</h6>
                    <div className="rate">
                      <Rate
                        onChange={(rate) => {
                          setRate(rate);
                        }}
                        value={rate}
                      />
                    </div>
                  </div>
                  <Input.TextArea
                    className="form-control web-textarea"
                    rows={5}
                    onChange={(e) => {
                      setReviewText(e.target.value);
                    }}
                    value={reviewText}
                  />
                </div>

                <div className="text-center">
                  <Button
                    type="primary"
                    className="btn web-btn"
                    onClick={handleSubmit}
                    disabled={reviewText === "" || rate === 0}
                  >
                    Post Review
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AsNavFor;
