import { Col, Row, Card, Rate, Button, Space, Input } from "antd";
import { useLocation, useNavigate } from "react-router";
import { FaCalendar } from "react-icons/fa";
import { FaArrowLeftLong, FaLocationDot } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { GIFT, UPLOADS_URL } from "../../../config/constants/api";
import { extractDate, extractTime } from "../../../config/constants";
import { Post } from "../../../config/api/post";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Modals from "../../../components/Modals";
import { addData } from "../../../redux/slice/afterPaymentSlice";

const JumpCheckout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [couponCode, setCouponCode] = useState("");
  const [totalPayable, setTotalPayable] = useState(null);
  const [giftId, setGiftId] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [isUtilized, setIsUtilized] = useState(null);
  const { selectedDate, selectedTime, dropzoneDetails, selectedAmenities } =
    location?.state;
  const token = useSelector((state) => state.user.userToken);
  // const profileDetails = useSelector((state) => state.user.profileDetails);
  const user = useSelector((state) => state.user.userData);

  const [isOpen, setIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const handleOk = () => {
    setTotalPayable(dropzoneDetails?.charges - discount);
    setIsUtilized(true)
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (
      !selectedDate ||
      !selectedTime ||
      !dropzoneDetails ||
      !selectedAmenities
    ) {
      navigate("/dropZone");
    }
    setTotalPayable(dropzoneDetails?.charges);
  }, []);
  const handleNavigate = () => {
    let jumpData = {
      gigTitle : dropzoneDetails?.title,
      charges : totalPayable,
      details : dropzoneDetails?.details,
      weather : 'sunny',
      jumpType : dropzoneDetails?.jumpType,
      amenities : selectedAmenities.map((amenity)=> amenity._id),
      selectedDate : selectedTime,
      time : selectedDate,
      gig : dropzoneDetails?._id,
      user : user?._id,
      customerName : user?.firstName + ' ' + user?.lastName,
      giftId,
      isUtilized
    };
    if(giftId){
      jumpData.giftId = giftId
    }
    dispatch(addData({ type: "JUMP", data: jumpData }));
    navigate("/payment", {
      state: { total: totalPayable, type: "JUMP" },
    });
  };
  const handleCouponSubmit = () => {
    if (couponCode === "") {
      return;
    } else {
      let body = {
        email: user?.email,
        couponCode: couponCode,
      };
      Post(GIFT.utilizeGift, body, token)
        .then((response) => {
          if (response.status) {
            setGiftId(response?.data?._id)
            setCouponCode("");
            setModalText(
              `Are you sure you want to utilize this Coupon ? you will get discount of ${
                response?.data?.amount
              }, your final payable amount will be ${
                dropzoneDetails?.charges - response?.data?.amount
              }.`
            );
            setDiscount(response?.data?.amount);
            setIsOpen(true);
          }
        })
        .catch((err) => {
          console.log("Error", err);
          swal("Error", err?.response?.data?.message, "error");
        });
    }
  };
  return (
    <div className="shop-page">
      <Modals
        open={isOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        text={modalText}
        okText={"Confirm"}
        cancelText={"Cancel"}
      />
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Jump checkout</h3>
              </div>
            </Col>
          </Row>
          <Row gutter={16} justify={"center"}>
            <Col lg={19}>
              <Card className="details-card">
                <Row align="middle" gutter={16}>
                  <Col lg={10}>
                    <div className="search-img">
                      <div>
                        <span>
                          <img
                            src={UPLOADS_URL + dropzoneDetails?.image}
                            alt=""
                            className="img-fluid"
                            style={{ width: "100%" }}
                          />
                        </span>
                      </div>
                      <div className="rating-box">
                        <Rate
                          allowHalf
                          // defaultValue={dropzoneData[0].rating}
                          defaultValue={4}
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={14}>
                    <div className="room-details">
                      <div>
                        <h4 className="text-26">{dropzoneDetails?.title}</h4>
                        <p className="">{dropzoneDetails?.description}</p>
                        <p className="icon-span">
                          <FaLocationDot />{" "}
                          {" " +
                            dropzoneDetails?.location?.address +
                            " ," +
                            dropzoneDetails?.location?.street +
                            " " +
                            dropzoneDetails?.location?.state}
                        </p>
                        <p className="icon-span">
                          <FaCalendar /> {extractDate(selectedDate)}
                        </p>
                        <p className="icon-span">
                          <FaCalendar /> {extractTime(selectedTime)}
                        </p>
                      </div>
                      <div className="search-result-detail-btnbox">
                        {totalPayable && (
                          <h6 className="per-day-price">
                            <p>Total Payable</p>${totalPayable}
                          </h6>
                        )}
                      </div>
                    </div>
                    <div className="search-img-box-right additional-details">
                      <h5 className="heading-18">Coupon Code/Gift Card Code</h5>
                      <Space.Compact
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          placeholder="Enter Coupon Code"
                          className="web-input"
                          onChange={(e) => {
                            setCouponCode(e.target.value);
                          }}
                          value={couponCode}
                        />
                        <Button
                          type=""
                          className="web-btn"
                          onClick={handleCouponSubmit}
                        >
                          Apply Coupon
                        </Button>
                      </Space.Compact>
                    </div>
                    <div>
                      <Button
                        type=""
                        className="web-btn"
                        onClick={handleNavigate}
                      >
                        Proceed To Pay
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default JumpCheckout;
