import { Row, Col, Image } from "antd";
import a1 from "../../../assets/a1.png";
import a2 from "../../../assets/a2.png";
import a3 from "../../../assets/a3.png";
import a4 from "../../../assets/a4.png";
import adbanner from "../../../assets/ad-banner.png";
import experienceflyImg2 from "../../../assets/experiencefly-img2.png";
import plainimg from "../../../assets/plane.png";
import { Link } from "react-router-dom";

const AboutUsHome = () => {
  return (
    <>
      <section
        className="experience-fly ant-experience-fly"
        style={{ paddingBottom: "0" }}
      >
        <div className="ant-container-fluid">
          <Row justify="center" gutter={16}>
            <Col lg={20} md={20} xs={23}>
              <Row align="middle" gutter={16}>
                <Col lg={12}>
                  <div className=" ant-experiencefly-imgbox">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={a1}
                      className=""
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <h2>Apply for diving stunt challenge</h2>
                  <p className="web-p pb-3">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </p>
                  <Link to={"/aboutUs"} className="web-btn ant-web-btn">
                    Apply challenge
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <section
        className="experience-fly ant-experience-fly"
        style={{ paddingBottom: "0" }}
      >
        <div className="ant-container-fluid">
          <Row justify="center" gutter={16}>
            <Col lg={20} md={20} xs={23}>
              <Row align="middle" gutter={16}>
              <Col lg={{ span: 12, order: 1 }} md={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }}>
                  <h2>Support Izzy’s wing foundation</h2>
                  <p className="web-p pb-3">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </p>
                  <Link to={"/aboutUs"} className="web-btn ant-web-btn">
                    Apply challenge
                  </Link>
                </Col>
                <Col lg={{ span: 12, order: 2 }} md={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
                  <div className=" ant-experiencefly-imgbox">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={a2}
                      className=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <section
        className="experience-fly ant-experience-fly"
        style={{ paddingBottom: "0" }}
      >
        <div className="ant-container-fluid">
          <Row justify="center" gutter={16}>
            <Col lg={20} md={20} xs={23}>
              <Row align="middle" gutter={16}>
                <Col lg={12}>
                  <div className=" ant-experiencefly-imgbox">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={a3}
                      className=""
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <h2>Winner Of the Month</h2>
                  <p className="web-p pb-3">
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source.
                  </p>
                  <Link to={"/aboutUs"} className="web-btn ant-web-btn">
                    Apply challenge
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <section className="experience-fly ant-experience-fly" style={{ paddingBottom: "0" }}>
        <div className="ant-container-fluid">
          <Row justify="center" gutter={16}>
            <Col xl={18} lg={20} md={20} xs={23}>
              <Row align="middle" gutter={16}>
              <Col lg={{ span: 12, order: 1 }} md={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }}>
                  <h2>the DZ/FBO of the month</h2>
                  <p className="web-p pb-3">
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source.
                  </p>
                  <Link to={"/aboutUs"} className="web-btn ant-web-btn">
                    Apply challenge
                  </Link>
                </Col>
                <Col lg={{ span: 12, order: 2 }} md={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
                  <div className=" ant-experiencefly-imgbox">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={a4}
                      className=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <section className="experience-fly ant-experience-fly">
        <div className="ant-container-fluid">
          <Row justify="center" gutter={16}>
            <Col xl={18} lg={20} md={20} xs={23}>
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={adbanner}
                className=""
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default AboutUsHome;
