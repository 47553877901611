import { useEffect } from "react";
import { Col, Row, Button } from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { UPLOADS_URL } from "../../../config/constants/api";
import DashbordSidebar from "../../../components/DashboardSidebar";
import { extractDate } from "../../../config/constants";
import { max } from "moment";

const DropzoneFiltercards = () => {
  // const profileDetails = useSelector((state) => state.user.profileDetails);
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  return (
    <div className="shop-page">
      {user && (
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={21}>
            <div className="shop-page-main">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                  <div className="my-account-profile">
                    <section className="side-menu-parent">
                      <DashbordSidebar />
                      <div className="about-us-section">
                        <div className="profile-information-wrapper">
                          <h3 className="main-heading">Profile Information</h3>
                        </div>
                        <div className="bg-parent">
                          <Row
                            gutter={[16, 16]}
                            align={""}
                            justify={"space-between"}
                          >
                            <Col md={10} lg={10} xl={8}>
                              <div className="wrapper-group-1000001858">
                                <img
                                  src={UPLOADS_URL + user?.image}
                                  alt=""
                                  className="img-fluid"
                                  style={{
                                    maxHeight: "350px",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={14} lg={14} xl={14}>
                              <Row gutter={[16, 16]}>
                                <Col lg={12}>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">First Name</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.firstName}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Phone Number</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.mobile}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Email</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.email}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Date Of Birth</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {extractDate(user?.dateOfBirth)}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Location</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      <div className="abc-location-town">
                                        {user?.city +
                                          " " +
                                          user?.state +
                                          " " +
                                          user?.country}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Home DZ/FBO</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.homeDZFBO}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Zip Code</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.zipCode}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Skydiving USPA #</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.uspaNo}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Gov ID Card back</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      <img
                                        src={UPLOADS_URL + user?.govIdBack}
                                        alt=""
                                        className="img-fluid"
                                        style={{
                                          maxHeight: "100px",
                                          maxWidth: "100px",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">License Front</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      <img
                                        src={UPLOADS_URL + user?.licenseFront}
                                        alt=""
                                        className="img-fluid"
                                        style={{
                                          maxHeight: "100px",
                                          maxWidth: "100px",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col lg={12}>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Last Name</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.lastName}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">
                                        Emergency Contact Number
                                      </p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.emergencyContact}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Preferred Name</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.preferredName}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Gender</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.gender}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Street Address  One</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      {user?.streetAddressOne}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Street Address Line Two</p>
                                    </div>
                                    <div className="jake-dawson1">
                                    {user?.streetAddressTwo}
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">Pilot license</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      Pilot license 1523
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">ID Card Front</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      <img
                                        src={UPLOADS_URL + user?.govIdFront}
                                        alt=""
                                        className="img-fluid"
                                        style={{
                                          maxHeight: "100px",
                                          maxWidth: "100px",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="p-20">
                                    <div className="">
                                      <p className="p-5">pilot License card</p>
                                    </div>
                                    <div className="jake-dawson1">
                                      <img
                                        src={UPLOADS_URL + user?.licenseCard}
                                        alt=""
                                        className="img-fluid"
                                        style={{
                                          maxHeight: "100px",
                                          maxWidth: "100px",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {user?.badge && (
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Earned Badge</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        <img
                                          src={UPLOADS_URL + user?.badge}
                                          alt=""
                                          className="img-fluid"
                                          style={{
                                            width: "120px",
                                            height: "120px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Col>

                                <Col lg={24}>
                                  <div className="frame-group">
                                    <div className="">
                                      <Button
                                        type=""
                                        block
                                        size={"large"}
                                        style={{ marginBottom: "10px" }}
                                        className="web-btn"
                                        onClick={() => navigate("/editProfile")}
                                      >
                                        Edit Profile
                                      </Button>
                                    </div>
                                    <div className="">
                                      <Button
                                        type=""
                                        block
                                        size={"large"}
                                        style={{ marginBottom: "10px" }}
                                        className="web-btn"
                                        onClick={() =>
                                          navigate("/changePassword")
                                        }
                                      >
                                        Change password
                                      </Button>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                      
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DropzoneFiltercards;
